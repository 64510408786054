import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Context } from "../types/interfaces";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { getDocumentTags } from "../utils/documentsFunctions";

export const useAuthedDocumentTagsQuery = () => {
  const authContext = useContext(AuthContext) as Context;
  const query = useQuery({
    queryKey: ["getDocumentTags"],
    queryFn: getDocumentTags,
  });
  const navigate = useNavigate();

  if (query.isError || !query.data) {
    console.log("IsError. ");
    if (
      query?.error instanceof AxiosError &&
      query.error.response?.status === 401
    ) {
      authContext.setAuthStatus(false);
      authContext.setRole(0);
      authContext.setUserId("");
      navigate("/");
    }
  }

  return query;
};
