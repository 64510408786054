import React, { useContext } from "react";
import { useAuthedPostsQuery } from "../../hooks/useAuthedPostsQuery";
import Spinner from "../partials/Spinner";
import styles from "./Noticeboard.module.scss";
import PostPreview from "../partials/PostPreview";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/authContext";
import { Context } from "../../types/interfaces";
import { Language } from "../../types/enums";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

const Noticeboard: React.FC = () => {
  const context = useContext(AuthContext) as Context;
  const { isLoading, isError, data } = useAuthedPostsQuery();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const pinPostMutation = useMutation({
    mutationFn: (updatePost: { postId: string; isPinned: boolean }) =>
      axios.put(`${process.env.REACT_APP_SERVER}/api/posts`, updatePost),
    onSettled: async () => {
      queryClient.refetchQueries({ queryKey: ["getPosts"] });
    },
    onError: (error: AxiosError) => {
      console.error(error);
    },
  });

  const pinPost = (postId: string, isPinned: boolean) => {
    pinPostMutation.mutate({ postId, isPinned });
  };

  return (
    <main className={styles.main}>
      <h2>{t("noticeboard.heading")}</h2>
      <section className={styles.postsContainer}>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p>An error has occured, could not fetch posts.</p>
        ) : data ? (
          data.map((post) => {
            if (!post.tags?.includes("Meeting Protocols")) {
              return (post.language === Language.English &&
                i18n.language === "en") ||
                (post.language === Language.Svenska &&
                  i18n.language === "sv") ? (
                <div key={post._id} className={styles.postContainer}>
                  <Link to={`/noticeboard/${post._id}`}>
                    <PostPreview {...post} />
                  </Link>
                  {context.role > 0 ? (
                    <button
                      className={styles.pinButton}
                      onClick={() => pinPost(post._id, !post.isPinned)}
                    >
                      {t(
                        post.isPinned
                          ? "postPreview.unpinPost"
                          : "postPreview.pinPost"
                      )}
                    </button>
                  ) : null}
                </div>
              ) : null;
            } else {
              return null;
            }
          })
        ) : null}
      </section>
    </main>
  );
};

export default Noticeboard;
