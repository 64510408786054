import React from "react";
import { useAuthedEmployeesQuery } from "../../hooks/useAuthedEmployeesQuery";
import Spinner from "../partials/Spinner";
import styles from "./Colleagues.module.scss";
import Employee from "../partials/Employee";
import { useTranslation } from "react-i18next";

const Colleagues: React.FC = () => {
  const { isLoading, isError, data } = useAuthedEmployeesQuery();
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      <h2>{t("colleagues.heading")}</h2>
      {isLoading ? (
        <Spinner />
      ) : data ? (
        <table className={styles.employeesTable}>
          <thead className={styles.tableHead}>
            <tr>
              <th>{t("colleagues.name")}</th>
              <th>{t("colleagues.phone")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((employee) => (
              <Employee key={employee.EmployeeId} {...employee} />
            ))}
          </tbody>
        </table>
      ) : isError ? (
        <p>An error has occured, could not fetch employees.</p>
      ) : null}
    </main>
  );
};

export default Colleagues;
