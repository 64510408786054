import React, { useContext, useRef, useState } from "react";
import { MdDeleteForever, MdEdit } from "react-icons/md/";
import i18n from "../../i18n";
import withBankID from "../hoc/withBankID";
import { IDocument, QrCodes, Context, ITag } from "../../types/interfaces";
import styles from "./Document.module.scss";
import { QRCodeSVG } from "qrcode.react";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/enums";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

interface Props extends IDocument {
  authStatusMessage: string;
  usingQr: boolean;
  qrCodes: QrCodes;
  errorMessage: string;
  tagsIsLoading: boolean;
  tagsIsError: boolean;
  tagsData: ITag[] | undefined;
  initAuthThisDevice: () => Promise<void>;
  initAuthOtherDevice: () => Promise<void>;
  initSignThisDevice: (fileName: string) => Promise<void>;
  initSignOtherDevice: (fileName: string) => Promise<void>;
  pollCollectStatus: (orderRef: string, useQr: boolean) => void;
  cancelSign: (orderRef: string) => Promise<void>;
  collectTimeoutID: ReturnType<typeof setTimeout>;
}

const Document: React.FC<Props> = ({
  Key,
  Url,
  deleteDocumentMutation,
  authStatusMessage,
  usingQr,
  qrCodes,
  Tagging,
  tagsIsLoading,
  tagsIsError,
  tagsData,
  errorMessage,
  initSignOtherDevice,
  initSignThisDevice,
  pollCollectStatus,
  cancelSign,
  collectTimeoutID,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationDelayedOpen, setShowConfirmationDelayedOpen] =
    useState(false);
  const [signInProgress, setSignInProgress] = useState(false);
  const [signInProgressDelayedOpen, setSignInProgressDelayedOpen] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editingDocument, setEditingDocument] = useState(false);
  const [nameInput, setNameInput] = useState(Key.replace(/\..+$/, ''));
  const [tags, setTags] = useState<string[]>(Tagging.map(tag => { return tag.Value }));
  const formMessageRef = useRef(null);
  const context = useContext(AuthContext) as Context;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isDocumentSigned =
    context.signedDocuments.filter((document) => document.fileName === Key)
      .length > 0
      ? true
      : false;
  console.log("isDocumentSigned: ", isDocumentSigned);

  const documentSignature = context.signedDocuments.filter(
    (document) => document.fileName === Key
  );

  const signatureDate = new Date(
    documentSignature.length > 0 && documentSignature[0].signedDate
      ? documentSignature[0].signedDate
      : Date.now()
  );
  console.log(signatureDate);

  const mutateDocument = useMutation({
    mutationFn: (data: { documentKey: string, tags: string[], newKey: string }) =>
      axios.put(`${process.env.REACT_APP_SERVER}/api/documents`, data)
    ,
    onSuccess: async () => {
      queryClient.invalidateQueries(["getDocuments"]);
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      setEditingDocument(false);
    },
  });

  const askConfirmation = () => {
    console.log("Asking confirmation.");
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmationDelayedOpen(true);
    }, 100);
  };

  const handleDelete = () => {
    deleteDocumentMutation.mutate(Key);
  };

  const handleSign = async () => {
    setSignInProgress(true);
    setTimeout(() => {
      setSignInProgressDelayedOpen(true);
    }, 100);
    initSignOtherDevice(Key);
  };

  const handleCancel = () => {
    context.setAutostarting(false);
    context.setAuthInProgress(false);
    console.log("Handling cancel.", collectTimeoutID);
    clearTimeout(collectTimeoutID);
    setSignInProgressDelayedOpen(false);
    setTimeout(() => {
      setSignInProgress(false);
    }, 200);
  };

  const handleClose = (): void => {
    handleCancel();
    setShowConfirmationDelayedOpen(false);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 200);
  };

  const handleChangeDevice = async (): Promise<void> => {
    try {
      console.log("Collect timeout: ", collectTimeoutID);
      clearTimeout(collectTimeoutID);
      await cancelSign(context.orderRef);
      initSignThisDevice(Key);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitEditDocument = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    mutateDocument.mutate({ documentKey: Key, tags, newKey: nameInput + Key.match(/\.[^.]+$/) })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedTag = e.target.value;
    if (e.target.checked) {
      setTags([...tags, checkedTag]);
    } else {
      setTags(tags.filter((tag) => tag !== checkedTag));
    }
  };

  return (
    <>
      <div className={`${styles.document} ${context.role === 0 ? styles.wideDocument : ''}`}>
        <div className={styles.documentInfo}>
          <a href={Url} target="_blank" rel="noreferrer">
            {Key}
          </a>
          <ul className={styles.tagList}>
            {Tagging.map((tag) => {
              return <li className={styles.tag} key={tag.Key}>{tag.Value}</li>
            })}
          </ul>
          {context.role > 0 ? (
            <div className={styles.buttonGroup}>
              <button title={`${t("document.editDocumentTitle")}`} onClick={() => setEditingDocument(!editingDocument)} className={styles.deleteButton}>
                <MdEdit className={styles.deleteIcon} />
              </button>
              <button title={`${t("document.deleteDocumentTitle")}`} onClick={askConfirmation} className={styles.deleteButton}>
                <MdDeleteForever className={styles.deleteIcon} />
              </button>
            </div>
          ) : null}
        </div>
        <div className={styles.documentSign}>
          {isDocumentSigned && context.role === 0 ? (
            <p className={styles.signed}>
              {t("document.signed")} {signatureDate.toLocaleDateString()}.
            </p>
          ) : context.role === 0 ? (
            <>
              <p className={styles.notSigned}>{t("document.notSigned")}</p>
              <button className={styles.button} onClick={() => handleSign()}>
                {t("document.signButton")}
              </button>
            </>
          ) : editingDocument ? (
            <>
              <form className={styles.editDocumentForm} onSubmit={(e) => handleSubmitEditDocument(e)}>
                <div className={styles.inputGroup}>
                  <label htmlFor="key">{t("document.fileName")}</label>
                  <div>
                    <input id="key" name="key" value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                    <span>{Key.match(/\.[^.]+$/)}</span>
                  </div>
                </div>
                <fieldset className={styles.tagField}>
                  <legend>{t("adminPage.tags")}</legend>
                  <ul className={styles.tagList}>
                    {!tagsIsLoading && !tagsIsError && tagsData
                      ? tagsData.map((tag) => {
                        return (tag.language === Language.English &&
                          i18n.language === "en") ||
                          (tag.language === Language.Svenska && i18n.language === "sv") ? (
                          <li className={styles.tagLi} key={tag.tag}>
                            <input
                              type="checkbox"
                              id={tag.tag}
                              value={tag.tag}
                              checked={tags.includes(tag.tag)}
                              name={tag.tag}
                              onChange={(e) => {
                                handleCheckboxChange(e);
                              }}
                            />
                            <div className={styles.tagDiv}>{tag.tag}</div>
                          </li>
                        ) : null;
                      })
                      : null}
                  </ul>
                </fieldset>
                <button className={`${styles.button} ${styles.updateButton}`}>{t("document.updateDocumentButton")}</button>
              </form>
            </>
          ) : (null)}
        </div>
      </div>
      {showConfirmation ? (
        <div
          className={`${styles.confirmationBackground} ${showConfirmationDelayedOpen ? styles.visible : ""
            }`}
          onClick={(e: any): void => {
            if (
              showConfirmation &&
              formMessageRef.current !== e.currentTarget
            ) {
              console.log(e.currentTarget);
              console.log(formMessageRef.current);
              handleClose();
            }
          }}
        >
          <div
            className={`${styles.confirmationMessage} ${showConfirmationDelayedOpen ? styles.visible : ""
              }`}
            ref={formMessageRef}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <p>{t("document.deleteConfirmation")}</p>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={(): void => handleClose()}
              >
                {t("document.cancel")}
              </button>
              <button
                className={`${styles.delete} ${styles.button}`}
                onClick={() => {
                  handleClose();
                  handleDelete();
                }}
              >
                {t("document.delete")}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {signInProgress && context.authInProgress ? (
        <div
          className={`${styles.confirmationBackground} ${signInProgressDelayedOpen ? styles.visible : ""
            }`}
          onClick={(e): void => {
            if (signInProgress && formMessageRef.current !== e.currentTarget) {
              console.log(e.currentTarget);
              console.log(formMessageRef.current);
              handleCancel();
            }
          }}
        >
          <div
            className={`${styles.confirmationMessage} ${styles.signMessage} ${signInProgressDelayedOpen ? styles.visible : ""
              }`}
            ref={formMessageRef}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <p className={styles.bankIdText}>
              Start the BankID app on your phone or tablet.
              <br />
              Tap the QR code button in the app.
              <br />
              Point the camera at the QR code below.
            </p>
            {usingQr && qrCodes && qrCodes.qrAuthCode ? (
              <>
                <QRCodeSVG
                  value={`bankid.${qrCodes ? qrCodes.qrStartToken : ""}.${qrCodes ? qrCodes.time : "0"
                    }.${qrCodes ? qrCodes.qrAuthCode : ""}`}
                />
                <p className={styles.statusMessage}>{authStatusMessage}</p>
              </>
            ) : null}
            <button
              className={styles.changeDeviceButton}
              onClick={(e): void => {
                handleChangeDevice();
                e.stopPropagation();
              }}
            >
              Use BankID on this device
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withBankID(Document);
