import axios from "axios";
import { IPost, ITag } from "../types/interfaces";

export const getPosts = async (): Promise<IPost[]> => {
  const posts = await axios.get(`${process.env.REACT_APP_SERVER}/api/posts`);

  return posts.data;
};

export const getPostTags = async (): Promise<ITag[]> => {
  const tags = await axios.get(
    `${process.env.REACT_APP_SERVER}/api/posts/tags`
  );

  return tags.data;
};

export const createPost = async (
  subject: string,
  body: string,
  userId: string
): Promise<any> => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/posts`, {
    subject,
    body,
    userId,
  });
};

export const visitPost = async (): Promise<any> => {
  return axios.put(`${process.env.REACT_APP_SERVER}/api/posts`);
};
