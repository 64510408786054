import React, { useState, useContext, useRef } from "react";
import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MdClose } from "react-icons/md";
import { TbCaretUpDown, TbCaretDownFilled, TbCaretUpFilled } from "react-icons/tb";
import Personnummer from "personnummer";
import { useNavigate } from "react-router-dom";
import Spinner from "../partials/Spinner";
import { AuthContext } from "../../context/authContext";
import { Context } from "../../types/interfaces";
import styles from "./SuperAdminPage.module.scss";
import CreateAdminForm from "../partials/CreateAdminForm";
import { useAuthedAdminsQuery } from "../../hooks/useAuthedAdminsQuery";
import { useAuthedEmployeesQuery } from "../../hooks/useAuthedEmployeesQuery";
import Admin from "../partials/Admin";

const SuperAdminPage: React.FC = () => {
  const { isLoading, isError, data } = useAuthedAdminsQuery();
  const { isLoading: employeesIsLoading, isError: employeesIsError, data: employeesData } = useAuthedEmployeesQuery();
  const context = useContext(AuthContext) as Context;
  const queryClient = useQueryClient();
  const [isFormSentVisible, setIsFormSentVisible] = useState(false);
  const [formMessageDelayedOpen, setFormMessageDelayedOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("surname"); // State to track the column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // State to track the sorting order
  const formMessageRef = useRef(null);
  const navigate = useNavigate();
  const mutateUser = useMutation({
    mutationFn: (newUser: {
      givenName: string;
      surname: string;
      email: string;
      personalNumber: number | string;
    }) => axios.post(`${process.env.REACT_APP_SERVER}/api/users`, newUser),
    onSuccess: async () => {
      queryClient.invalidateQueries(["users"]);
      setIsFormSentVisible(true);
      setTimeout(() => {
        console.log("Setting formMessageDelayedOpen.");
        setFormMessageDelayedOpen(true);
      }, 70);
      setTimeout(async () => {
        console.log("Awaiting handleClose.");
        handleClose();
      }, 3000);
    },
    onError: async (error: AxiosError) => {
      console.log(error?.response?.status);
      if (error.response?.status === 401) {
        context.setAuthStatus(false);
        navigate("/");
      } else if (error.response?.status === 409) {
        if (typeof error.response.data == "string") {
          setErrorMessage(error.response?.data);
        } else {
          setErrorMessage("A user with the personal number already exists.");
        }
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    },
  });

  const handleClose = (): void => {
    setFormMessageDelayedOpen(false);
    setTimeout(() => {
      setIsFormSentVisible(false);
    }, 200);
  };

  const submitAdmin = (
    e: React.FormEvent,
    givenName: string,
    surname: string,
    email: string,
    personalNumber: number | string
  ) => {
    e.preventDefault();
    if (!Personnummer.valid(personalNumber.toString())) {
      setErrorMessage("Vänligen skriv ett giltigt personnummer, 12 siffror.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }
    mutateUser.mutate(
      {
        givenName,
        surname,
        email,
        personalNumber: Number(personalNumber),
      },
      {
        onError: () => {
          setTimeout(() => mutateUser.reset(), 3000);
        },
      }
    );
  };

  const handleSort = (column: string) => {
    console.log(column);
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  }

  const sortedData = data?.slice().sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    } else {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    }
  });

  return (
    <main className={styles.main}>
      <h2>Super Admin Page</h2>
      {isLoading || employeesIsLoading ? (
        <Spinner />
      ) : sortedData ? (
        <table className={styles.employeesTable}>
          <thead className={styles.tableHead}>
            <tr>
              <th className={styles.sort} onClick={() => handleSort("surname")}>Name<span className={styles.caret}>{sortBy === 'surname' ? sortOrder === 'asc' ? <TbCaretDownFilled /> : <TbCaretUpFilled /> : <TbCaretUpDown />}</span></th>
              <th className={styles.sort} onClick={() => handleSort("created")}>Created<span className={styles.caret}>{sortBy === 'created' ? sortOrder === 'asc' ? <TbCaretDownFilled /> : <TbCaretUpFilled /> : <TbCaretUpDown />}</span></th>
              <th className={styles.sort} onClick={() => handleSort("latestLogin")}>Last Logged In<span className={styles.caret}>{sortBy === 'latestLogin' ? sortOrder === 'asc' ? <TbCaretDownFilled /> : <TbCaretUpFilled /> : <TbCaretUpDown />}</span></th>
              <th className={styles.sort} onClick={() => handleSort("personalNumber")}>Personal Number<span className={styles.caret}>{sortBy === 'personalNumber' ? sortOrder === 'asc' ? <TbCaretDownFilled /> : <TbCaretUpFilled /> : <TbCaretUpDown />}</span></th>
              <th className={styles.sort} onClick={() => handleSort("role")}>Role<span className={styles.caret}>{sortBy === 'role' ? sortOrder === 'asc' ? <TbCaretDownFilled /> : <TbCaretUpFilled /> : <TbCaretUpDown />}</span></th>
              <th>Is Employed</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((admin) => {
              const employeeExists = employeesData?.filter(employee => {
                return employee.FirstName.toUpperCase() === admin.givenName.toUpperCase() && employee.LastName.toUpperCase() === admin.surname.toUpperCase()
              })
              return <Admin key={admin._id} admin={admin} isEmployed={employeeExists ? true : false} />
            })}
          </tbody>
        </table>
      ) : isError || employeesIsError ? (
        <p>An error has occured, could not fetch admins.</p>
      ) : null}
      <h3>Lägg till en ny administratör:</h3>
      <CreateAdminForm status={mutateUser.status} submitAdmin={submitAdmin} />
      <p className={styles.errorMessage}>
        {mutateUser.status === "error" && !errorMessage
          ? "Something went wrong, please try again later."
          : errorMessage
            ? errorMessage
            : null}
      </p>
      {isFormSentVisible ? (
        <div
          className={`${styles.formSentMessageBackground} ${formMessageDelayedOpen ? styles.visible : ""
            }`}
          onClick={(e: any): void => {
            if (
              isFormSentVisible &&
              formMessageRef.current !== e.currentTarget
            ) {
              console.log(e.currentTarget);
              console.log(formMessageRef.current);
              handleClose();
            }
          }}
        >
          <div
            className={`${styles.formSentMessage} ${formMessageDelayedOpen ? styles.visible : ""
              }`}
            ref={formMessageRef}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <button onClick={(): void => setIsFormSentVisible(false)}>
              <MdClose />
            </button>
            <p>Form sent successfully!</p>
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default SuperAdminPage;
