import React from "react";
import { IEmployee } from "../../types/interfaces";
import styles from "./Employee.module.scss";

const Employee: React.FC<IEmployee> = (employee) => {
  return (
    <tr>
      <td>
        {employee.FirstName} {employee.LastName}
      </td>
      <td className={styles.phone}>{employee.Mobile}</td>
    </tr>
  );
};

export default Employee;
