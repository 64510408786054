import React, { ChangeEvent, useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import styles from "./UploadFileForm.module.scss";
import adminStyles from "../pages/AdminPage.module.scss";
import Spinner from "./Spinner";
import { Language } from "../../types/enums";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthedDocumentTagsQuery } from "../../hooks/useAuthedDocumentTagsQuery";
import axios, { AxiosError } from "axios";

interface IProps {
  status: string;
  submitFile: (
    event: React.FormEvent,
    file: File,
    language: Language,
    tags: string[]
  ) => void;
}

const UploadFileForm: React.FC<IProps> = ({ status, submitFile }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [language, setLanguage] = useState(Language.English);
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState("");
  const queryClient = useQueryClient();

  const {
    isLoading: tagsLoading,
    isError: tagsError,
    data: tagsData,
  } = useAuthedDocumentTagsQuery();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedTag = e.target.value;
    if (e.target.checked) {
      setTags([...tags, checkedTag]);
    } else {
      setTags(tags.filter((tag) => tag !== checkedTag));
    }
  };

  const mutateTags = useMutation({
    mutationFn: (newTag: { tag: string; language: Language }) =>
      axios.post(`${process.env.REACT_APP_SERVER}/api/documents/tags`, newTag),
    onSuccess: async () => {
      setNewTag("");
      queryClient.invalidateQueries(["getDocumentTags"]);
    },
    onError: (error: AxiosError) => {
      console.error(error);
    },
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={async (e): Promise<void> => {
        await submitFile(e, file as File, language, tags);
        setTimeout(() => {
          if (status !== "loading") {
            setFile(undefined);
          }
        }, 1000);
      }}
      encType="multipart/form-data"
    >
      <label className={styles.fileUploadLabel}>
        <span className={styles.fileUploadText}>
          Click or Drag a file here...
        </span>
        <MdCloudUpload className={styles.fileUploadIcon} />
        <span role="presentation" className={styles.fileUploadFileName}>
          {file ? file.name : "No file selected"}
        </span>
        <input
          className={styles.fileUploadInput}
          name="file"
          type="file"
          onChange={handleFileChange}
          required
        />
      </label>
      <fieldset className={`${adminStyles.languageField} ${styles.languageField}`}>
        <legend>{t("adminPage.legend")}</legend>
        <div className={styles.radioGroup}>
          <input
            className={adminStyles.radioButton}
            type="radio"
            name="uploadDocumentLanguageChoice1"
            id="uploadDocumentLanguageChoice1"
            value="english"
            onChange={(e) => setLanguage(Language.English)}
            checked={language === Language.English}
          />
          <label htmlFor="uploadDocumentLanguageChoice1">English</label>
        </div>
        <div className={styles.radioGroup}>
          <input
            className={adminStyles.radioButton}
            type="radio"
            name="uploadDocumentLanguageChoice2"
            id="uploadDocumentLanguageChoice2"
            value="swedish"
            onChange={(e) => setLanguage(Language.Svenska)}
            checked={language === Language.Svenska}
          />
          <label htmlFor="uploadDocumentLanguageChoice2">Svenska</label>
        </div>
      </fieldset>
      <fieldset className={styles.tagField}>
        <legend>{t("adminPage.tags")}</legend>
        <ul className={styles.tagList}>
          {!tagsLoading && !tagsError && tagsData
            ? tagsData.map((tag) => {
              return tag.language === language ? (
                <li className={styles.tagLi} key={tag.tag}>
                  <input
                    type="checkbox"
                    id={tag.tag}
                    value={tag.tag}
                    checked={tags.includes(tag.tag)}
                    name={tag.tag}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />
                  <div className={styles.tagDiv}>{tag.tag}</div>
                </li>
              ) : null;
            })
            : null}
        </ul>
        <div className={adminStyles.inputGroup}>
          <label htmlFor="tag">{`${t("adminPage.newTag")}:`}</label>
          <div className={styles.newTagGroup}>
            <input
              name="newTag"
              id="newTag"
              type="text"
              className={adminStyles.newTagInput}
              value={newTag}
              onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                setNewTag(e.currentTarget.value);
              }}
            />
            <button
              className={styles.button}
              onClick={(e) => {
                e.preventDefault();
                mutateTags.mutate({ tag: newTag, language })
              }
              }
            >
              {t("adminPage.newTagButton")}
            </button>
          </div>
        </div>
      </fieldset>
      <button
        className={styles.button}
        type="submit"
        disabled={status === "loading"}
        aria-busy={status === "loading"}
      >
        {status !== "loading" ? `${t("adminPage.submit")}` : <Spinner />}
      </button>
    </form>
  );
};

export default UploadFileForm;
