import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./CreatePostForm.module.scss";
import adminStyles from "../pages/AdminPage.module.scss";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/enums";

interface IProps {
  status: string;
  submitPost: (
    event: React.FormEvent,
    subject: string,
    body: string,
    language: Language,
    tags: string[]
  ) => void;
}

const CreatePostForm: React.FC<IProps> = ({ status, submitPost }) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [body, setBody] = useState("");
  const [language, setLanguage] = useState(Language.English);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedTag = e.target.value;
    if (e.target.checked) {
      setTags([...tags, checkedTag]);
    } else {
      setTags(tags.filter((tag) => tag !== checkedTag));
    }
  };

  return (
    <form
      className={adminStyles.form}
      onSubmit={(e): void => {
        submitPost(e, subject, body, language, tags);
        setSubject("");
        setBody("");
        setTags([]);
        setLanguage(Language.English);
      }}
    >
      <div className={adminStyles.inputGroup}>
        <label htmlFor="subject">{`${t("adminPage.postSubject")}:`}</label>
        <input
          name="subject"
          id="subject"
          type="text"
          value={subject}
          required
          placeholder={`${t("adminPage.postSubject")}...`}
          className={adminStyles.subjectInput}
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            setSubject(e.currentTarget.value);
          }}
        />
      </div>
      <div className={adminStyles.inputGroup}>
        <label htmlFor="body">{t("adminPage.postBody")}</label>
        <ReactQuill
          theme="snow"
          className={adminStyles.quill}
          value={body}
          onChange={setBody}
        />
      </div>
      <fieldset className={adminStyles.languageField}>
        <legend>{t("adminPage.legend")}</legend>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="languageChoice"
            id="languageChoice1"
            value={Language.English}
            onChange={(e) => setLanguage(Language.English)}
            checked={language === Language.English}
          />
          <label htmlFor="languageChoice1">English</label>
        </div>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="languageChoice"
            id="languageChoice2"
            value={Language.Svenska}
            onChange={(e) => setLanguage(Language.Svenska)}
            checked={language === Language.Svenska}
          />
          <label htmlFor="languageChoice2">Svenska</label>
        </div>
      </fieldset>
      <fieldset className={styles.tagField}>
        <legend>{t("adminPage.tags")}</legend>
        <ul className={styles.tagList}>
          <li className={styles.tagLi}>
            <input
              type="checkbox"
              id={"meetingProtocols"}
              value={"Meeting Protocols"}
              checked={tags.includes("Meeting Protocols")}
              name={"Meeting Protocols"}
              onChange={(e) => {
                handleCheckboxChange(e);
              }}
            />
            <div className={styles.tagDiv}>{t("meetingProtocols.heading")}</div>
          </li>
        </ul>
      </fieldset>
      <button
        className={adminStyles.button}
        type="submit"
        disabled={status === "loading"}
        aria-busy={status === "loading"}
      >
        {status !== "loading" ? `${t("adminPage.submit")}` : <Spinner />}
      </button>
    </form>
  );
};

export default CreatePostForm;
