import React, { useContext, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";
import { Context, QrCodes } from "../../types/interfaces";
import Spinner from "../partials/Spinner";
import styles from "./BankID.module.scss";
import { checkCurrentUser } from "../../utils/CheckCurrentUser";
import withBankID from "../hoc/withBankID";
import { useTranslation } from "react-i18next";

interface Props {
  authStatusMessage: string;
  usingQr: boolean;
  qrCodes: QrCodes;
  errorMessage: string;
  initAuthThisDevice: () => Promise<void>;
  initAuthOtherDevice: () => Promise<void>;
  pollCollectStatus: (orderRef: string, useQr: boolean) => void;
  cancelSign: (orderRef: string) => Promise<void>;
}

const BankId: React.FC<Props> = ({
  authStatusMessage,
  usingQr,
  qrCodes,
  errorMessage,
  initAuthThisDevice,
  initAuthOtherDevice,
}) => {
  const context = useContext(AuthContext) as Context;
  const { authInProgress } = context;
  const { isLoading, isError, data } = useQuery({
    queryKey: ["currentUser"],
    queryFn: checkCurrentUser,
    networkMode: "online",
  });
  const { t } = useTranslation();
  let navigate = useNavigate();
  useEffect(() => {
    console.log("UseEffect bankid.");
    if (!isLoading && !isError && data) {
      context.setAuthStatus(true);
      context.setRole(data.role);
      navigate("/noticeboard");
    }
  }, [isLoading, isError, data, context, navigate]);

  return (
    <main className={styles.main}>
      {!authInProgress ? (
        <div className={styles.formContainer}>
          <h2>{t("bankID.heading")}</h2>
          <button
            className={styles.button}
            onClick={() => initAuthThisDevice()}
            type="button"
            disabled={authInProgress}
          >
            {authInProgress ? (
              <Spinner width="auto" />
            ) : (
              `${t("bankID.thisDevice")}`
            )}
          </button>
          <button
            className={styles.button}
            onClick={() => initAuthOtherDevice()}
            type="button"
            disabled={authInProgress}
          >
            {authInProgress ? (
              <Spinner width="auto" />
            ) : (
              `${t("bankID.otherDevice")}`
            )}
          </button>
          <p className={styles.errorMessage}>
            {errorMessage ? errorMessage : null}
          </p>
          <Link to="login">{t("bankID.login")}</Link>
        </div>
      ) : usingQr && qrCodes && qrCodes.qrAuthCode ? (
        <div className={styles.formContainer}>
          <QRCodeSVG
            value={`bankid.${qrCodes ? qrCodes.qrStartToken : ""}.${
              qrCodes ? qrCodes.time : "0"
            }.${qrCodes ? qrCodes.qrAuthCode : ""}`}
          />
          <p className={styles.statusMessage}>{authStatusMessage}</p>
        </div>
      ) : (
        <div className={styles.formContainer}>
          <Spinner />
          <p className={styles.statusMessage}>{authStatusMessage}</p>
        </div>
      )}
    </main>
  );
};
export default withBankID(BankId);
