import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import smoothscroll from "smoothscroll-polyfill";
import { MdDeleteForever, MdEdit } from "react-icons/md/";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import Spinner from "../partials/Spinner";
import EditPostForm from "../partials/EditPostForm";
import styles from "./PostPage.module.scss";
import { useAuthedPostsQuery } from "../../hooks/useAuthedPostsQuery";
import { Context } from "../../types/interfaces";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Visits {
  [key: string]: number;
}

const PostPage: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationDelayedOpen, setShowConfirmationDelayedOpen] =
    useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const formMessageRef = useRef(null);
  const queryClient = useQueryClient();
  const context = useContext(AuthContext) as Context;
  const { postId } = useParams();
  const postsQuery = useAuthedPostsQuery();
  console.log(postsQuery, "PostId: ", postId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  smoothscroll.polyfill();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SERVER}/api/posts`,
          {
            postId,
          }
        );

        console.log("Put response: ", res);
      } catch (error) {
        if (error.response.status !== 304) {
          console.error(error);
        }
      }
    })();
  }, [postId]);

  const deletePostMutation = useMutation({
    mutationFn: async (postId: string) => {
      await axios.delete(`${process.env.REACT_APP_SERVER}/api/posts/${postId}`);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["getPosts"]);
      console.log("Successfully deleted post.");
    },
  });

  const askConfirmation = () => {
    console.log("Asking confirmation.");
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmationDelayedOpen(true);
    }, 100);
  };

  const handleClose = (): void => {
    setShowConfirmationDelayedOpen(false);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 200);
  };

  const handleDelete = (postId: string) => {
    deletePostMutation.mutate(postId);
  };

  if (postsQuery.isLoading) {
    return <Spinner />;
  }

  if (postsQuery.isError) {
    console.log("Errored postsQuery");
    return <p>Something went wrong, please try again later.</p>;
  }

  if (postsQuery.isSuccess) {
    console.log("postsquery success");
    const post = postsQuery.data.find((post) => {
      return post._id === postId;
    });

    if (post) {
      const dateObject = new Date(post.created);
      const time = dateObject.toLocaleTimeString("se", { timeStyle: "short" });
      const date = dateObject.toLocaleDateString("se", { dateStyle: "short" });

      // Loop through visitedBy and make a Date object, find it in an array and push
      // a new object in if it isn't found with LocaleDateString as the key and ++ the value

      let visitsObj: Visits = {};
      post.visitedBy.forEach((postVisit) => {
        const visitDate = new Date(postVisit.visitedAt).toLocaleDateString();
        if (visitDate in visitsObj) {
          visitsObj[visitDate as keyof typeof visitsObj]++;
        } else {
          visitsObj[visitDate] = 1;
        }
      });

      return (
        <main
          className={`${styles.main} ${context.role > 0 ? styles.adminView : null
            }`}
        >
          {isEditing ? (
            <EditPostForm post={post} setIsEditing={setIsEditing} />
          ) : (
            <article className={styles.post}>
              {context.role > 0 ? (
                <button
                  onClick={() => setIsEditing(true)}
                  className={styles.editButton}
                >
                  <MdEdit className={styles.editIcon} />
                </button>
              ) : null}
              <h3>{post.subject}</h3>
              {post.tags && post.tags.length > 0 && !post.tags.includes("Meeting Protocols") ? (
                <ul className={styles.tagList}>
                  {post.tags.map((tag, index) => (
                    <li key={`${tag} ${index}`} className={styles.tagDiv}>{tag}</li>
                  ))}
                </ul>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
              <p className={styles.authorContainer}>
                <span className={styles.author}>
                  {t("postPage.author")}: {post.authorGivenName}{" "}
                  {post.authorSurname}
                </span>
                <span className={styles.date}>
                  {t("postPage.date")}: {date} {time}
                </span>
              </p>
              {context.role > 0 ? (
                <button
                  onClick={askConfirmation}
                  className={styles.deleteButton}
                >
                  <MdDeleteForever className={styles.deleteIcon} />
                </button>
              ) : null}
            </article>
          )}
          {showConfirmation ? (
            <div
              className={`${styles.confirmationBackground} ${showConfirmationDelayedOpen ? styles.visible : ""
                }`}
              onClick={(e: any): void => {
                if (
                  showConfirmation &&
                  formMessageRef.current !== e.currentTarget
                ) {
                  console.log(e.currentTarget);
                  console.log(formMessageRef.current);
                  handleClose();
                }
              }}
            >
              <div
                className={`${styles.confirmationMessage} ${showConfirmationDelayedOpen ? styles.visible : ""
                  }`}
                ref={formMessageRef}
                onClick={(e): void => {
                  e.stopPropagation();
                }}
              >
                <p>{t("document.deleteConfirmation")}</p>
                <div className={styles.buttons}>
                  <button
                    className={styles.button}
                    onClick={(): void => handleClose()}
                  >
                    {t("document.cancel")}
                  </button>
                  <button
                    className={`${styles.delete} ${styles.button}`}
                    onClick={() => {
                      handleClose();
                      handleDelete(postId as string);
                      navigate("/noticeboard");
                    }}
                  >
                    {t("document.delete")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {context.role > 0 ? (
            <section className={styles.statisticsContainer}>
              <div className={styles.statistics}>
                <h3>{t("postPage.statistics")}</h3>
                <div>
                  <p>
                    {t("postPage.peopleVisited")}: {post.visitedBy.length}
                  </p>
                  {Object.entries(visitsObj).map((date) => {
                    return (
                      <p>
                        {date[0]}: {date[1]}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div
                ref={divRef}
                className={`${styles.notSigned} ${expanded ? styles.expanded : null
                  }`}
              >
                <h3>{t("postPage.notVisited")}</h3>
                <ul>
                  {post.notVisited.map((visit) => {
                    return <li key={visit}>{visit}</li>;
                  })}
                </ul>
                <button
                  onClick={() => {
                    setExpanded(!expanded);
                    divRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className={styles.expandButton}
                >
                  {expanded ? <BiCaretUp /> : <BiCaretDown />}
                </button>
              </div>
            </section>
          ) : null}
        </main>
      );
    } else {
      return <p>Something went wrong, please try again later.</p>;
    }
  } else {
    return <p>Something went wrong, please try again later.</p>;
  }
};

export default PostPage;
