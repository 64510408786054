import React from "react";
import { useTranslation } from "react-i18next";
import { IPost } from "../../types/interfaces";
import styles from "./PostPreview.module.scss";

const PostPreview: React.FC<IPost> = (post) => {
  const { t } = useTranslation();

  return (
    <article
      className={`${styles.post} ${post.isPinned ? styles.isPinned : null}`}
    >
      <h3>{post.subject}</h3>
      <div className={styles.bodyText}>
        <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
      </div>
      <button className={styles.readMoreBtn}>
        {t("postPreview.readMore")}
      </button>
    </article>
  );
};

export default PostPreview;
