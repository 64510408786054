import React, { useState } from "react";
import styles from "./DeleteTagsForm.module.scss";
import adminStyles from "../pages/AdminPage.module.scss";
import Spinner from "./Spinner";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/enums";
import { ITag } from "../../types/interfaces";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useAuthedDocumentTagsQuery } from "../../hooks/useAuthedDocumentTagsQuery";

const DeleteTagsForm: React.FC = () => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(Language.English);
  const queryClient = useQueryClient();

  const {
    isLoading: documentTagsLoading,
    isError: documentTagsError,
    data: documentTagsData,
  } = useAuthedDocumentTagsQuery();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedTag = e.target.value;
    if (e.target.checked) {
      setTags([...tags, { tag: checkedTag, language }]);
    } else {
      console.log(e.target.checked, checkedTag, language);
      console.log(tags.filter((tag) => tag.tag !== checkedTag));
      setTags(tags.filter((tag) => tag.tag !== checkedTag));
    }
  };

  const deleteTags = useMutation({
    mutationFn: (tagsToDelete: ITag[]) => {
      return axios.post(`${process.env.REACT_APP_SERVER}/api/documents/tags/delete`, {
        tags: tagsToDelete,
      })
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["getDocumentTags"]);
    },
    onSettled: async () => {
      setLoading(false);
    },
    onError: (error: AxiosError) => {
      console.error(error);
    },
  });

  return (
    <form
      className={adminStyles.form}
      onSubmit={(e): void => {
        e.preventDefault();
        setLoading(true);
        deleteTags.mutate(tags);
        setTags([]);
        setLanguage(Language.English);
      }}
    >
      <fieldset className={adminStyles.languageField}>
        <legend>{t("adminPage.legend")}</legend>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="deleteTagsLanguageChoice"
            id="deleteTagsLanguageChoice1"
            value={Language.English}
            onChange={(e) => {
              setLanguage(Language.English);
              setTags([]);
            }}
            checked={language === Language.English}
          />
          <label htmlFor="deleteTagsLanguageChoice1">English</label>
        </div>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="deleteTagsLanguageChoice"
            id="deleteTagsLanguageChoice2"
            value={Language.Svenska}
            onChange={(e) => {
              setLanguage(Language.Svenska);
              setTags([]);
            }}
            checked={language === Language.Svenska}
          />
          <label htmlFor="deleteTagsLanguageChoice2">Svenska</label>
        </div>
      </fieldset>
      <fieldset className={styles.tagField}>
        <legend>{t("adminPage.tags")}</legend>
        <ul className={styles.tagList}>
          {!documentTagsLoading && !documentTagsError && documentTagsData
            ? documentTagsData.map((tag) => {
              console.log("Document Tags Data: ", documentTagsData);
              return tag.language === language ? (
                <li className={styles.tagLi} key={tag.tag}>
                  <input
                    type="checkbox"
                    id={tag.tag}
                    value={tag.tag}
                    checked={
                      tags.find((tagInList) => tagInList.tag === tag.tag)
                        ? true
                        : false
                    }
                    name={tag.tag}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />
                  <div className={styles.tagDiv}>{tag.tag}</div>
                </li>
              ) : null;
            })
            : null}
        </ul>
      </fieldset>
      <button
        className={adminStyles.button}
        type="submit"
        disabled={loading}
        aria-busy={loading}
      >
        {!loading ? `${t("adminPage.delete")}` : <Spinner />}
      </button>
    </form>
  );
};

export default DeleteTagsForm;
