import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";

const NotFound: React.FC = () => {
  return (
    <main className={styles.main}>
      <h2>404 - Page not found</h2>
      <Link to="/">Back to the home page</Link>
    </main>
  );
};

export default NotFound;
