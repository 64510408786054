import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosError } from "axios";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "react-quill/dist/quill.snow.css";
import styles from "./EditPostForm.module.scss";
import adminStyles from "../pages/AdminPage.module.scss";
import Spinner from "./Spinner";
import { Language } from "../../types/enums";
import { useAuthedPostTagsQuery } from "../../hooks/useAuthedPostTagsQuery";
import { Context, IPost } from "../../types/interfaces";
import { AuthContext } from "../../context/authContext";

interface IProps {
  post: IPost;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const EditPostForm: React.FC<IProps> = ({ post, setIsEditing }) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext) as Context;
  const { t } = useTranslation();
  const [subject, setSubject] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [body, setBody] = useState("");
  const [language, setLanguage] = useState(Language.English);
  const [errorMessage, setErrorMessage] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    setSubject(post.subject);
    setBody(post.body);
    setLanguage(post.language);
    setTags(post.tags ? post.tags : []);
  }, [post]);

  const mutatePost = useMutation({
    mutationFn: (updatedPost: {
      postId: string;
      subject: string;
      body: string;
      language: Language;
      userId: string;
      tags: string[];
    }) => axios.put(`${process.env.REACT_APP_SERVER}/api/posts`, updatedPost),
    onSuccess: async () => {
      queryClient.invalidateQueries(["posts"]);
      setIsEditing(false);
      navigate("/");
    },
    onError: (error: AxiosError) => {
      setErrorMessage(
        error.message
          ? error.message
          : "Something went wrong, please try again later."
      );
    },
  });

  const submitPost = (
    e: React.FormEvent,
    subject: string,
    body: string,
    language: Language,
    tags: string[]
  ) => {
    e.preventDefault();
    mutatePost.mutate({
      postId: post._id,
      subject,
      body,
      language,
      userId: context.userId,
      tags,
    });
  };

  const {
    isLoading: tagsLoading,
    isError: tagsError,
    data: tagsData,
  } = useAuthedPostTagsQuery();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedTag = e.target.value;
    if (e.target.checked) {
      setTags([...tags, checkedTag]);
    } else {
      setTags(tags.filter((tag) => tag !== checkedTag));
    }
  };

  return (
    <form
      className={adminStyles.form}
      onSubmit={(e): void => {
        submitPost(e, subject, body, language, tags);
        setSubject("");
        setBody("");
        setTags([]);
        setLanguage(Language.English);
      }}
    >
      <div className={adminStyles.inputGroup}>
        <label htmlFor="subject">{`${t("adminPage.postSubject")}:`}</label>
        <input
          name="subject"
          id="subject"
          type="text"
          value={subject}
          required
          placeholder={`${t("adminPage.postSubject")}...`}
          className={adminStyles.subjectInput}
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            setSubject(e.currentTarget.value);
          }}
        />
      </div>
      <div className={adminStyles.inputGroup}>
        <label htmlFor="body">{t("adminPage.postBody")}</label>
        <ReactQuill
          theme="snow"
          className={adminStyles.quill}
          value={body}
          onChange={setBody}
        />
      </div>
      <fieldset className={adminStyles.languageField}>
        <legend>{t("adminPage.legend")}</legend>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="languageChoice"
            id="languageChoice1"
            value={Language.English}
            onChange={(e) => setLanguage(Language.English)}
            checked={language === Language.English}
          />
          <label htmlFor="languageChoice1">English</label>
        </div>
        <div className={adminStyles.radioGroup}>
          <input
            className={styles.radioButton}
            type="radio"
            name="languageChoice"
            id="languageChoice2"
            value={Language.Svenska}
            onChange={(e) => setLanguage(Language.Svenska)}
            checked={language === Language.Svenska}
          />
          <label htmlFor="languageChoice2">Svenska</label>
        </div>
      </fieldset>
      <fieldset className={styles.tagField}>
        <legend>{t("adminPage.tags")}</legend>
        <ul className={styles.tagList}>
          {!tagsLoading && !tagsError && tagsData
            ? tagsData.map((tag) => {
              return tag.language === language ? (
                <li className={styles.tagLi} key={tag.tag}>
                  <input
                    type="checkbox"
                    id={tag.tag}
                    value={tag.tag}
                    checked={tags.includes(tag.tag)}
                    name={tag.tag}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />
                  <div className={styles.tagDiv}>{tag.tag}</div>
                </li>
              ) : null;
            })
            : null}
        </ul>
      </fieldset>
      <button
        className={adminStyles.button}
        type="submit"
        disabled={mutatePost.status === "loading"}
        aria-busy={mutatePost.status === "loading"}
      >
        {mutatePost.status !== "loading" ? (
          `${t("adminPage.submit")}`
        ) : (
          <Spinner />
        )}
      </button>
      {errorMessage ? <p>{errorMessage}</p> : null}
    </form>
  );
};

export default EditPostForm;
