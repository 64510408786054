import axios from "axios";
import { IDocument, ITag } from "../types/interfaces";

export const getDocuments = async (): Promise<IDocument[] | undefined> => {
  const files = await axios.get(
    `${process.env.REACT_APP_SERVER}/api/documents`
  );

  return files.data;
};

export const getDocumentTags = async (): Promise<ITag[]> => {
  const tags = await axios.get(
    `${process.env.REACT_APP_SERVER}/api/documents/tags`
  );

  return tags.data;
};

export const uploadDocument = async (file: File): Promise<any> => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/documents`, {
    file,
  });
};
