import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "./Header.module.scss";
import { useLocation, Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import Menu from "./Menu";
import logo from "../../images/anne-blom-logo.svg";
import menuIcon from "../../images/menu-icon.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { MdLanguage } from "react-icons/md";

const Header: React.FC = () => {
  const context = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClosing, setMenuClosing] = useState(false);
  let location = useLocation();
  const prevLocation = useRef(location);
  const { t } = useTranslation();

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "sv" ? "en" : "sv");
  };

  const handleClose = (): void => {
    if (menuOpen) {
      setMenuClosing(true);
      setTimeout(() => {
        setMenuOpen(false);
        setMenuClosing(false);
      }, 200);
    } else {
      setMenuOpen(true);
    }
  };

  useEffect(() => {
    if (location !== prevLocation.current && menuOpen) {
      handleClose();
    }
    prevLocation.current = location;
  });

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to={context && context.authStatus ? "/noticeboard" : "/"}>
          <img className={styles.logo} src={logo} alt="" />
        </Link>
        <h1>{t('header.heading')}</h1>
      </div>
      {context && context.authStatus ? (
        <>
          <button className={styles.menuIcon} onClick={(): void => handleClose()}>
            <img src={menuIcon} alt="" />
          </button>
        
        <div
          className={`${styles.menuBackground} ${
            menuOpen ? styles.menuBackgroundOpen : null
          }`}
          onClick={() => handleClose()}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              handleClose();
            }
          }}
          role="button"
          tabIndex={0}
        ></div>
        <Menu
          menuOpen={menuOpen}
          menuClosing={menuClosing}
          handleClose={handleClose}
        />
      </>
      ) : (
        <button className={styles.languageButton} onClick={() => switchLanguage()}>
          <span>{t("menu.language")}</span>
          <MdLanguage className={styles.langIcon} />
        </button>
      )}
    </header>
  );
};

export default Header;
