import React, { useContext, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthedDocumentsQuery } from "../../hooks/useAuthedDocumentsQuery";
import { useAuthedDocumentTagsQuery } from "../../hooks/useAuthedDocumentTagsQuery";
import Spinner from "../partials/Spinner";
import styles from "./Documents.module.scss";
import noticeboardStyles from "./Noticeboard.module.scss";
import Document from "../partials/Document";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { AuthContext } from "../../context/authContext";
import { Context } from "../../types/interfaces";
import NotSigned from "../partials/NotSigned";
import { Language } from "../../types/enums";

const Documents: React.FC = () => {
  const [currentTags, setCurrentTags] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const context = useContext(AuthContext) as Context;
  const { isLoading, isError, data } = useAuthedDocumentsQuery();
  const {
    isLoading: tagsIsLoading,
    isError: tagsIsError,
    data: tagsData,
  } = useAuthedDocumentTagsQuery();
  const deleteDocumentMutation = useMutation({
    mutationFn: async (documentKey: string) => {
      await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/documents/${documentKey}`
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["getDocuments"]);
      console.log("Successfully deleted document.");
    },
  });

  const handleToggleTags = (tag: string) => {
    if (currentTags.includes(tag)) {
      const filteredTags = currentTags.filter((curTag) => curTag !== tag);
      setCurrentTags(filteredTags);
    } else setCurrentTags([...currentTags, tag]);
  };

  return (
    <main className={styles.main}>
      <h2>{t("documents.heading")}</h2>
      {tagsData && !tagsIsError && !tagsIsLoading ? (
        <ul className={noticeboardStyles.tagList}>
          {tagsData.map((tag) => {
            return (tag.language === Language.English &&
              i18n.language === "en") ||
              (tag.language === Language.Svenska && i18n.language === "sv") ? (
              <li
                key={tag.tag}
                className={`${noticeboardStyles.tag} ${currentTags.includes(tag.tag) ? noticeboardStyles.checked : null
                  }`}
                onClick={() => handleToggleTags(tag.tag)}
              >
                {tag.tag}
              </li>
            ) : null;
          })}
        </ul>
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : data && data instanceof Array ? (
        data.map((document, index) => {
          console.log(document);
          let isTagged = false;
          document.Tagging?.forEach((tag) =>
            currentTags.includes(tag.Value) ? (isTagged = true) : null
          );
          return (document.ContentLanguage === i18n.language && (currentTags.length === 0 || isTagged)) ? (
            <div
              className={`${context.role > 0 ? styles.documentGroup : ''}`}
              key={`${document.Key} ${index}`}
            >
              <Document
                {...document}
                tagsIsLoading={tagsIsLoading}
                tagsIsError={tagsIsError}
                tagsData={tagsData}
                deleteDocumentMutation={deleteDocumentMutation}
              />
              {context.role > 0 ? (
                <NotSigned notSigned={document.NotSigned} />
              ) : null}
            </div>
          ) : null;
        })
      ) : isError ? (
        <p>An error has occured, could not fetch documents.</p>
      ) : null}
    </main>
  );
};

export default Documents;
