/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { UserRoles } from "../../types/enums";
import { checkCurrentUser } from "../../utils/CheckCurrentUser";
import Spinner from "../partials/Spinner";

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (context && !context.authStatus) {
      (async () => {
        try {
          setLoading(true);
          const user = await checkCurrentUser();
          if (user) {
            context.setAuthStatus(true);
            context.setRole(user.role);
            context.setSignedDocuments(user.signedDocuments);
            context.setUserId(user._id);
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [context]);

  return context && !loading ? (
    context &&
    context.authStatus &&
    (context.role === UserRoles.Admin ||
      context.role === UserRoles.SuperAdmin) ? (
      children
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    )
  ) : (
    <Spinner />
  );
};

export default AdminRoute;
