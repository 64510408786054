import axios from "axios";
import { IEmployee, IUser } from "../types/interfaces";

export const getEmployees = async (): Promise<IEmployee[] | undefined> => {
  const employees = await axios.get(
    `${process.env.REACT_APP_SERVER}/api/tengella/employees`
  );

  return employees.data;
};

export const getAdmins = async (): Promise<IUser[] | undefined> => {
  const users = await axios.get(`${process.env.REACT_APP_SERVER}/api/users`);

  return users.data;
};
