import React, { useEffect, useState, useContext, MouseEvent } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdClose, MdLanguage } from "react-icons/md";
import { AuthContext } from "../../context/authContext";
import { UserRoles } from "../../types/enums";
import Spinner from "./Spinner";
import styles from "./Menu.module.scss";
import { Context } from "../../types/interfaces";

interface Props {
  menuOpen: boolean;
  menuClosing: boolean;
  handleClose: () => void;
}

const Menu: React.FC<Props> = ({ menuOpen, menuClosing, handleClose }) => {
  const [delayedOpen, setDelayedOpen] = useState(false);
  const context = useContext(AuthContext) as Context;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (menuOpen) {
      setTimeout(() => {
        setDelayedOpen(true);
      }, 50);
    }
  }, [menuOpen]);

  const handleLogout = async (
    e: MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    await axios.get(`${process.env.REACT_APP_SERVER}/api/auth/logout`);
    context.setAuthStatus(false);
    navigate("/");
    // setTimeout(() => {
    //   context?.setAuthStatus(false);
    //   navigate("/");
    // }, 100);
  };

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "sv" ? "en" : "sv");
  };

  return context ? (
    <nav
      className={`${styles.menu} ${delayedOpen && !menuClosing && menuOpen ? styles.open : ""
        }`}
      id="navbar"
    >
      <button className={styles.langButton} onClick={() => switchLanguage()}>
        <span>{t("menu.language")}</span>
        <MdLanguage className={styles.langIcon} />
      </button>
      <button
        className={styles.closeButton}
        onClick={(): void => handleClose()}
      >
        <MdClose style={{ fontSize: "2rem" }} />
      </button>
      <Link to="/">{t("menu.noticeboard")}</Link>
      {context.isPublicUser ? null : (
        <Link to="/documents">{t("menu.documents")}</Link>
      )}
      <Link to="/meeting-protocols">{t("menu.meetingProtocols")}</Link>
      <Link to="/colleagues">{t("menu.colleagues")}</Link>
      {/* <Link to="/settings">{t("menu.settings")}</Link> */}
      {context.role === UserRoles.Admin ||
        context.role === UserRoles.SuperAdmin ? (
        <Link to="/admin">{t("menu.adminPage")}</Link>
      ) : null}
      {context.role === UserRoles.SuperAdmin ? (
        <Link to="/superadmin">{t("menu.superAdminPage")}</Link>
      ) : null}
      <button onClick={handleLogout}>{t("menu.logout")}</button>
    </nav>
  ) : (
    <Spinner />
  );
};

export default Menu;
