import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md/";
import { IUser } from "../../types/interfaces";
import styles from "./Admin.module.scss";

interface Props {
  admin: IUser,
  isEmployed: boolean
}

const Admin: React.FC<Props> = ({ admin, isEmployed }) => {
  const queryClient = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationDelayedOpen, setShowConfirmationDelayedOpen] =
    useState(false);
  const formMessageRef = useRef(null);
  const createdDate = new Date(admin.created);
  const latestLoginDate = new Date(admin.latestLogin);

  const formatDate = (date: Date) => {
    const dateString = date.toLocaleDateString();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${dateString} ${hours}:${minutes}`;
  };

  const formatPersonalNumber = (personalNumber: number): string => {
    const string = personalNumber.toString();
    const startString = string.substring(0, string.length - 4);
    const endString = string.substring(string.length - 4, string.length);
    const formattedString = `${startString}-${endString}`;

    return formattedString;
  };

  const deleteUserMutation = useMutation({
    mutationFn: async (personalNumber: number) => {
      await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/users/${personalNumber}`
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["getAdmins"]);
      console.log("Successfully deleted admin user.");
    },
  });

  const askConfirmation = () => {
    console.log("Asking confirmation.");
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmationDelayedOpen(true);
    }, 100);
  };

  const handleDelete = () => {
    deleteUserMutation.mutate(admin.personalNumber);
  };

  const handleClose = (): void => {
    setShowConfirmationDelayedOpen(false);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 200);
  };

  return (
    <>
      {showConfirmation ? (
        <div
          className={`${styles.confirmationBackground} ${showConfirmationDelayedOpen ? styles.visible : ""
            }`}
          onClick={(e: any): void => {
            if (
              showConfirmation &&
              formMessageRef.current !== e.currentTarget
            ) {
              console.log(e.currentTarget);
              console.log(formMessageRef.current);
              handleClose();
            }
          }}
        >
          <div
            className={`${styles.confirmationMessage} ${showConfirmationDelayedOpen ? styles.visible : ""
              }`}
            ref={formMessageRef}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <p>Are you sure you want to delete this administrator?</p>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                onClick={(): void => handleClose()}
              >
                Cancel
              </button>
              <button
                className={`${styles.delete} ${styles.button}`}
                onClick={() => {
                  handleClose();
                  handleDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <tr>
        <td>
          {admin.givenName} {admin.surname}
        </td>
        <td className={styles.email}>{formatDate(createdDate)}</td>
        <td className={styles.phone}>{formatDate(latestLoginDate)}</td>
        <td>{formatPersonalNumber(admin.personalNumber)}</td>
        <td>{admin.role}</td>
        <td>{`${isEmployed}`}</td>
        <td>
          <button className={styles.deleteButton} onClick={askConfirmation}>
            <MdDeleteForever />
          </button>
        </td>
      </tr>
    </>
  );
};

export default Admin;
