import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NotSigned.module.scss";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import smoothscroll from "smoothscroll-polyfill";

const NotSigned: React.FC<{ notSigned: string[] }> = ({ notSigned }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  smoothscroll.polyfill();

  return (
    <div
      ref={divRef}
      className={`${styles.notSigned} ${expanded ? styles.expanded : null}`}
    >
      <h2>{t("document.notSigned")}</h2>
      <ul>
        {notSigned.map((signature) => {
          return <li key={signature}>{signature}</li>;
        })}
      </ul>
      <button
        onClick={() => {
          setExpanded(!expanded);
          divRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
        }}
        className={styles.expandButton}
      >
        {expanded ? <BiCaretUp /> : <BiCaretDown />}
      </button>
    </div>
  );
};

export default NotSigned;
