import React, { Suspense } from "react";
import axios from "axios";
import "./App.scss";
import "./utilities.scss";
import BankID from "./components/pages/BankID";
import Header from "./components/partials/Header";
import Footer from "./components/partials/Footer";
import Noticeboard from "./components/pages/Noticeboard";
import Documents from "./components/pages/Documents";
import Settings from "./components/pages/Settings";
import NotFound from "./components/pages/NotFound";
import SuperAdminRoute from "./components/routing/SuperAdminRoute";
import AdminRoute from "./components/routing/AdminRoute";
import UserRoute from "./components/routing/UserRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthContextProvider from "./context/authContext";
import MeetingProtocols from "./components/pages/MeetingProtocols";
import Colleagues from "./components/pages/Colleagues";
import AdminPage from "./components/pages/AdminPage";
import SuperAdminPage from "./components/pages/SuperAdminPage";
import Spinner from "./components/partials/Spinner";
import PostPage from "./components/pages/PostPage";
import Login from "./components/pages/Login";

const App: React.FC = () => {
  axios.defaults.withCredentials = true;
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<BankID />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="noticeboard"
                element={
                  <UserRoute>
                    <Noticeboard />
                  </UserRoute>
                }
              />
              <Route path="noticeboard/:postId" element={<PostPage />} />
              <Route
                path="documents"
                element={
                  <UserRoute>
                    <Documents />
                  </UserRoute>
                }
              />
              <Route
                path="meeting-protocols"
                element={
                  <UserRoute>
                    <MeetingProtocols />
                  </UserRoute>
                }
              />
              <Route
                path="colleagues"
                element={
                  <UserRoute>
                    <Colleagues />
                  </UserRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <UserRoute>
                    <Settings />
                  </UserRoute>
                }
              />
              <Route
                path="admin"
                element={
                  <AdminRoute>
                    <AdminPage />
                  </AdminRoute>
                }
              />
              <Route
                path="superadmin"
                element={
                  <SuperAdminRoute>
                    <SuperAdminPage />
                  </SuperAdminRoute>
                }
              />
              <Route path="/*" element={<NotFound />}></Route>
            </Routes>
            <Footer />
          </BrowserRouter>
        </Suspense>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
