import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Settings.module.scss";

const Settings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      <h2>{t("settings.heading")}</h2>
    </main>
  );
};

export default Settings;
