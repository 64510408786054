/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { checkCurrentUser } from "../../utils/CheckCurrentUser";
import Spinner from "../partials/Spinner";

const UserRoute = ({ children }: { children: JSX.Element }) => {
  const context = useContext(AuthContext);
  let location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (context && !context.authStatus) {
      (async () => {
        try {
          setLoading(true);
          const user = await checkCurrentUser();
          console.log("/anslagstavla checking current user. ", user);
          if (user) {
            context.setAuthStatus(true);
            context.setRole(user.role);
            context.setSignedDocuments(user.signedDocuments);
            context.setUserId(user._id);

            if (user.givenName === "Public") {
              context.setIsPublicUser(true);
            }
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      })();
    }
  }, [context]);

  console.log("Authstatus: ", context?.authStatus);
  console.log("Role: ", context?.role);

  return context && !loading ? (
    context.authStatus ? (
      children
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    )
  ) : (
    <Spinner />
  );
};

export default UserRoute;
