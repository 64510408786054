import { bankIDStatusMessages } from "./bankIDStatusMessages";

const isMobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

export const getBankIDStatusMessage = (
  status: string,
  hintCode: string,
  autostarting: boolean
): string => {
  if (status === "pending") {
    switch (hintCode) {
      case "outstandingTransaction":
        return autostarting
          ? bankIDStatusMessages.RFA13
          : bankIDStatusMessages.RFA1;
      case "noClient":
        return bankIDStatusMessages.RFA1;
      case "started":
        if (autostarting) {
          return !isMobile
            ? bankIDStatusMessages.RFA15A
            : bankIDStatusMessages.RFA15B;
        } else {
          return !isMobile
            ? bankIDStatusMessages.RFA14A
            : bankIDStatusMessages.RFA14B;
        }
      case "userSign":
        return bankIDStatusMessages.RFA9;
      default:
        return bankIDStatusMessages.RFA21;
    }
  } else if (status === "failed") {
    switch (hintCode) {
      case "startFailed":
        return bankIDStatusMessages.RFA17A;
      case "expiredTransaction":
        return bankIDStatusMessages.RFA8;
      case "certificateErr":
        return bankIDStatusMessages.RFA16;
      case "userCancel":
        return bankIDStatusMessages.RFA6;
      case "cancelled":
        return bankIDStatusMessages.RFA3;
      default:
        return bankIDStatusMessages.RFA22;
    }
  }

  return bankIDStatusMessages.RFA21;
};
