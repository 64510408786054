import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import styles from "./Login.module.scss";
import adminStyles from "./AdminPage.module.scss";
import { useTranslation } from "react-i18next";
import { Context } from "../../types/interfaces";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const context = useContext(AuthContext) as Context;
  const { t } = useTranslation();
  let navigate = useNavigate();

  const submitLogin = async (
    e: React.FormEvent,
    username: string,
    password: string
  ) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/login/`,
        { username, password }
      );

      if (res.status === 200) {
        console.log("Navigating to /noticeboard.");
        context.setAuthInProgress(false);
        context.setAutostarting(false);
        context.setAuthStatus(true);
        context.setRole(0);
        context.setSignedDocuments([]);
        context.setIsPublicUser(true);
        navigate("/noticeboard");
      }

      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className={styles.main}>
      <form
        className={adminStyles.form}
        onSubmit={(e): void => {
          submitLogin(e, username, password);
        }}
      >
        <div className={adminStyles.inputGroup}>
          <label htmlFor="username">{`${t("login.username")}:`}</label>
          <input
            name="username"
            id="username"
            type="text"
            value={username}
            required
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              setUsername(e.currentTarget.value);
            }}
          />
        </div>
        <div className={adminStyles.inputGroup}>
          <label htmlFor="password">{`${t("login.password")}:`}</label>
          <input
            name="password"
            id="password"
            type="password"
            value={password}
            required
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              setPassword(e.currentTarget.value);
            }}
          />
        </div>
        <button className={adminStyles.button} type="submit">
          {t("login.submit")}
        </button>
      </form>
    </main>
  );
};
export default Login;
