import React, { useState, useContext, useRef } from "react";
import axios, { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MdClose } from "react-icons/md";
import { AuthContext } from "../../context/authContext";
import { Context } from "../../types/interfaces";
import styles from "./AdminPage.module.scss";
import CreatePostForm from "../partials/CreatePostForm";
import DeleteTagsForm from "../partials/DeleteTagsForm";
import UploadFileForm from "../partials/UploadFileForm";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/enums";

const AdminPage: React.FC = () => {
  const context = useContext(AuthContext) as Context;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isFormSentVisible, setIsFormSentVisible] = useState(false);
  const [formMessageDelayedOpen, setFormMessageDelayedOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formMessageRef = useRef(null);
  const mutatePost = useMutation({
    mutationFn: (newPost: {
      subject: string;
      body: string;
      language: Language;
      userId: string;
      tags: string[];
    }) => axios.post(`${process.env.REACT_APP_SERVER}/api/posts`, newPost),
    onSuccess: async () => {
      queryClient.invalidateQueries(["posts"]);
      setIsFormSentVisible(true);
      setTimeout(() => {
        console.log("Setting formMessageDelayedOpen.");
        setFormMessageDelayedOpen(true);
      }, 70);
      setTimeout(async () => {
        console.log("Awaiting handleClose.");
        handleClose();
      }, 3000);
    },
    onError: (error: AxiosError) => {
      setErrorMessage(
        error.message
          ? error.message
          : "Something went wrong, please try again later."
      );
    },
  });

  const mutateFile = useMutation({
    mutationFn: (newFile: FormData) =>
      axios.post(`${process.env.REACT_APP_SERVER}/api/documents`, newFile, {
        headers: { "Content-Type": "multipart/form-data" }
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["getDocuments"]);
      setIsFormSentVisible(true);
      setTimeout(() => {
        console.log("Setting formMessageDelayedOpen.");
        setFormMessageDelayedOpen(true);
      }, 70);
      setTimeout(async () => {
        console.log("Awaiting handleClose.");
        handleClose();
      }, 3000);
    },
    onError: (error: AxiosError) => {
      setErrorMessage(
        error.message
          ? error.message
          : "Something went wrong, please try again later."
      );
    },
  });

  const handleClose = (): void => {
    setFormMessageDelayedOpen(false);
    setTimeout(() => {
      setIsFormSentVisible(false);
    }, 200);
  };

  const submitPost = (
    e: React.FormEvent,
    subject: string,
    body: string,
    language: Language,
    tags: string[]
  ) => {
    e.preventDefault();
    mutatePost.mutate({
      subject,
      body,
      language,
      userId: context.userId,
      tags,
    });
  };

  const submitFile = (e: React.FormEvent, file: File, language: Language, tags: string[]) => {
    e.preventDefault();
    console.log("Language: ", language);
    let tagString = '';

    if (tags && tags.length > 0) {
      tags.forEach((tag: string, index: number) => {
        tagString += index === 0 ? `tag${index}=${tag}` : `&tag${index}=${tag}`;
      });
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("language", language === Language.English ? "en" : "sv");
    formData.append("tags", tagString);
    mutateFile.mutate(formData);
  };

  return (
    <main className={styles.main}>
      <h2>{t("adminPage.heading")}</h2>
      <h3>{t("adminPage.postHeading")}</h3>
      <CreatePostForm status={mutatePost.status} submitPost={submitPost} />
      {mutatePost.status === "error" ? (
        <p className={styles.errorMessage}>
          {errorMessage ? errorMessage : t("adminPage.error")}
        </p>
      ) : null}
      <hr className={styles.divider} />
      <h3>{t("adminPage.fileHeading")}</h3>
      <UploadFileForm status={mutateFile.status} submitFile={submitFile} />
      {mutateFile.status === "error" ? (
        <p className={styles.errorMessage}>
          {errorMessage ? errorMessage : t("adminPage.error")}
        </p>
      ) : null}
      <hr className={styles.divider} />
      <h3>{t("adminPage.deleteTagsHeading")}</h3>
      <DeleteTagsForm />
      {isFormSentVisible ? (
        <div
          className={`${styles.formSentMessageBackground} ${formMessageDelayedOpen ? styles.visible : ""
            }`}
          onClick={(e: any): void => {
            if (
              isFormSentVisible &&
              formMessageRef.current !== e.currentTarget
            ) {
              console.log(e.currentTarget);
              console.log(formMessageRef.current);
              handleClose();
            }
          }}
        >
          <div
            className={`${styles.formSentMessage} ${formMessageDelayedOpen ? styles.visible : ""
              }`}
            ref={formMessageRef}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <button onClick={(): void => setIsFormSentVisible(false)}>
              <MdClose />
            </button>
            <p>{t("adminPage.formSent")}</p>
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default AdminPage;
