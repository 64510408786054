import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { AuthContext } from "../../context/authContext";
import { Context, QrCodes } from "../../types/interfaces";
import { getBankIDStatusMessage } from "../../utils/bankIDutilFunctions";

const withBankID = (Component: React.FC<any>) => (props: any) => {
  const [authStatusMessage, setAuthStatusMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usingQr, setUsingQr] = useState(false);
  const [qrCodes, setqrCodes] = useState<QrCodes>();
  const [collectTimeoutID, setCollecTimeoutID] = useState<NodeJS.Timeout>();
  const context = useContext(AuthContext) as Context;
  let navigate = useNavigate();

  const pollCollectStatus = async (
    orderRef: string,
    useQr: boolean,
    signing?: boolean,
    fileName?: string
  ) => {
    try {
      console.log("File name: ", fileName);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/collect/`,
        {
          orderRef,
          signing,
          fileName,
        }
      );

      const { status, hintCode, givenName, surname, role, signedDocuments } =
        res.data;

      if (status === "pending") {
        console.log("Pending.");
        console.log("UseQR: ", useQr);
        setAuthStatusMessage(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );

        if (useQr) {
          const qrRes = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/bankid/generateQr/`
          );

          setqrCodes({
            qrStartToken: qrRes.data.qrStartToken,
            time: qrRes.data.qrSecondsSinceAuth,
            qrAuthCode: qrRes.data.qrAuthCode,
          });
        }

        setCollecTimeoutID(
          setTimeout(() => {
            console.log("QrCodes: ", qrCodes);
            pollCollectStatus(orderRef, useQr, signing, fileName);
          }, 2000)
        );
      } else if (givenName && surname && role >= 0) {
        if (!signing) {
          console.log("Navigating to /noticeboard.");
          context.setAuthInProgress(false);
          setUsingQr(false);
          context.setAutostarting(false);
          context.setAuthStatus(true);
          context.setRole(res.data.role);
          context.setSignedDocuments(signedDocuments);
          navigate("/noticeboard");
        }
        console.log("Setting signedDocuments: ", signedDocuments);
        context.setSignedDocuments(signedDocuments);
        context.setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
      } else if (status === "failed") {
        console.log("Failed.");
        context.setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
        setErrorMessage(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      } else {
        context.setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
        setAuthStatusMessage(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );
      }
    } catch (error) {
      context.setAuthInProgress(false);
      setUsingQr(false);
      context.setAutostarting(false);
      setErrorMessage(
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  const initAuthThisDevice = async (): Promise<void> => {
    context.setAuthInProgress(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/auth/`,
        {
          useQr: false,
        }
      );

      const autoStartUrl = isIOS || isAndroid
        ? `https://app.bankid.com/?autostarttoken=${res.data.autoStartToken}&redirect=null`
        : `bankid:///?autostarttoken=${res.data.autoStartToken}&redirect=null`;

      const win = window.open(autoStartUrl, "_self");
      if (win != null) {
        win.focus();
      }

      context.setAutostarting(true);
      pollCollectStatus(res.data.orderRef, false, false);
    } catch (error) {
      console.error(error);
      setErrorMessage("Inloggning misslyckades. Vänligen försök igen.");
      context.setAuthInProgress(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const initAuthOtherDevice = async (): Promise<void> => {
    context.setAutostarting(false);
    context.setAuthInProgress(true);
    setUsingQr(true);
    const useQr = true;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/auth/`,
        {
          useQr: true,
        }
      );

      setqrCodes({
        qrStartToken: res.data.qrStartToken,
        time: res.data.qrSecondsSinceAuth,
        qrAuthCode: res.data.qrAuthCode,
      });

      pollCollectStatus(res.data.orderRef, useQr, false);
    } catch (error) {
      console.error(error);
      setErrorMessage("Något gick fel, vänligen försök igen.");
      context.setAuthInProgress(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const initSignThisDevice = async (fileName: string): Promise<void> => {
    console.log("Running initSignThisDevice.");
    context.setAuthInProgress(true);
    const userVisibleData = `Sign that you have read and understood the document ${fileName}.`;
    context.setAutostarting(true);

    try {
      console.log("Starting sign this device.");
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/sign/`,
        {
          useQr: false,
          signing: true,
          fileName,
          userVisibleData,
        }
      );

      context.setOrderRef(res.data.orderRef);

      const autoStartUrl = isIOS
        ? `https://app.bankid.com/?autostarttoken=${res.data.autoStartToken}&redirect=null`
        : `bankid:///?autostarttoken=${res.data.autoStartToken}&redirect=null`;

      const win = window.open(autoStartUrl, "_self");
      if (win != null) {
        win.focus();
      }

      pollCollectStatus(res.data.orderRef, false, true, fileName);
    } catch (error) {
      console.error(error);
      setErrorMessage("Inloggning misslyckades. Vänligen försök igen.");
      context.setAuthInProgress(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const initSignOtherDevice = async (fileName: string): Promise<void> => {
    context.setAutostarting(false);
    context.setAuthInProgress(true);
    const userVisibleData = `Sign that you have read and understood the document ${fileName}.`;
    setUsingQr(true);
    const useQr = true;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/sign/`,
        {
          useQr: true,
          signing: true,
          fileName,
          userVisibleData,
        }
      );

      context.setOrderRef(res.data.orderRef);

      setqrCodes({
        qrStartToken: res.data.qrStartToken,
        time: res.data.qrSecondsSinceAuth,
        qrAuthCode: res.data.qrAuthCode,
      });

      pollCollectStatus(res.data.orderRef, useQr, true, fileName);
    } catch (error) {
      console.error(error);
      setErrorMessage("Något gick fel, vänligen försök igen.");
      context.setAuthInProgress(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const cancelSign = async (orderRef: string): Promise<void> => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER}/api/bankid/cancel`, {
        orderRef,
      });
    } catch (error) {
      console.error(error);
      setErrorMessage("Något gick fel, vänligen försök igen.");
      context.setAuthInProgress(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <Component
      {...props}
      authStatusMessage={authStatusMessage}
      usingQr={usingQr}
      errorMessage={errorMessage}
      qrCodes={qrCodes}
      initAuthThisDevice={initAuthThisDevice}
      initAuthOtherDevice={initAuthOtherDevice}
      initSignThisDevice={initSignThisDevice}
      initSignOtherDevice={initSignOtherDevice}
      cancelSign={cancelSign}
      collectTimeoutID={collectTimeoutID}
    />
  );
};

export default withBankID;
