import React, { useState } from "react";
import styles from "./CreateAdminForm.module.scss";
import Spinner from "./Spinner";

interface IProps {
  status: string;
  submitAdmin: (
    event: React.FormEvent,
    givenName: string,
    surname: string,
    email: string,
    personalNumber: number | string
  ) => void;
}

const CreateAdminForm: React.FC<IProps> = ({ status, submitAdmin }) => {
  const [givenName, setGivenName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [personalNumber, setPersonalNumber] = useState<number | string>("");

  return (
    <form
      className={styles.form}
      onSubmit={(e): void =>
        submitAdmin(e, givenName, surname, email, personalNumber)
      }
    >
      <div className={styles.nameInputGroup}>
        <div className={styles.singleNameGroup}>
          <label htmlFor="subject">First name:</label>
          <input
            name="givenName"
            type="text"
            value={givenName}
            required
            placeholder="Förnamn..."
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              setGivenName(e.currentTarget.value);
            }}
          />
        </div>
        <div className={styles.singleNameGroup}>
          <label htmlFor="subject">Surname:</label>
          <input
            name="surname"
            type="text"
            value={surname}
            required
            placeholder="Efternamn..."
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              setSurname(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="email">Email:</label>
        <input
          name="email"
          type="email"
          value={email}
          required
          placeholder="Email..."
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            setEmail(e.currentTarget.value);
          }}
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="personalNumber">Personnummer:</label>
        <input
          name="personalNumber"
          type="text"
          pattern="[0-9]*"
          placeholder="ÅÅÅÅMMDDXXXX"
          value={personalNumber}
          required
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            if (e.currentTarget.validity.valid) {
              setPersonalNumber(e.currentTarget.value);
            }
          }}
        />
      </div>
      <button
        className={styles.button}
        type="submit"
        disabled={status === "loading"}
      >
        {status !== "loading" ? "Submit" : <Spinner />}
      </button>
    </form>
  );
};

export default CreateAdminForm;
